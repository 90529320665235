import styled, { css } from 'styled-components';
import {
  black,
  emperor,
  geyser,
  paleSky,
  shark,
  simpleGray,
  supernova,
  white,
} from '../../constants/theme';

export const Block = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
`;

export const About = styled.div`
  margin: -3rem auto 6.5rem auto;
`;

export const HeroBlock = styled.section`
  margin-top: 3rem;
  margin-bottom: 5rem;
  position: relative;
  z-index: 1;
`;

export const HeroBackground = styled.div`
  position: absolute;
  right: -10rem;
  top: 30rem;
  z-index: -1;

  svg {
    width: 400px;
    height: 400px;
  }
`;

export const Hero = styled.section`
  background: ${white};
  background-repeat: no-repeat;
  background-position: 100% -50%;
  background-size: auto;
  border-radius: 10px;
  box-shadow: 0px 10px 40px rgb(0 0 0 / 21%);
  height: 480px;
  margin-top: 2rem;
  padding: 5rem;
  width: 800px;
`;

export const HeroTitle = styled.h2`
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;
  margin-bottom: 3rem;
  width: 450px;
  color: ${supernova};
`;

export const SubTitle = styled.div`
  color: ${white};
  font-size: 1.8rem;
  line-height: 2.4rem;
  max-width: 41rem;
  margin-left: 0.3rem;
  margin-bottom: 2.5rem;
`;

export const HeroParagraph = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  left: 177px;
  line-height: 24px;
  width: 450px;
  text-justify: inter-word;
  text-align: justify;

  a {
    color: ${supernova};
    cursor: pointer;
    text-decoration: none;
  }
`;

export const ContentBackground = styled.div`
  position: absolute;
  right: -5rem;
  top: 135rem;
`;

export const Content = styled.div`
  margin-top: 5rem;
  position: relative;
`;

export const ContentBlock = styled.div`
  width: 45rem;
`;

export const Paragraph = styled.p`
  color: ${emperor};
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  margin-left: 5rem;
  margin-top: 2rem;
  margin-bottom: 5rem;
  a {
    color: ${supernova};
    text-decoration: none;
  }
`;

export const Quote = styled.p`
  color: ${simpleGray};
  font-style: italic;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  margin-left: 5rem;
`;

export const Members = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  list-style: none;
  margin-left: auto;
  margin-right: auto;
  max-width: 70rem;
  position: relative;
  z-index: 1;
`;

export const Member = styled.div`
  width: 17rem;
  margin: 2rem;
  position: relative;
`;

export const Role = styled.div`
  background: #ffc20e;
  border-radius: 100rem;
  flex: 0;
  order: 0;
  flex-grow: 0;
  margin: 0px 0px;
  position: absolute;
  padding: 2.5px 10px;
  font-size: 12px;
  top: -5px;
  left: 5px;
`;

export const Img = styled.img`
  width: 154px;
  height: 157px;
`;

export const Name = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 20px;
  text-align: center;
  color: ${shark};
`;

export const Expertise = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: ${simpleGray};
`;

const tumbBoxWidth = 340;
const tumbBoxHeight = 260;
const gapSize = 8;
const paddingSize = 16;

export const HeroContentContainer = styled.div`
  width: ${(tumbBoxWidth * 2) + (paddingSize * 2) + gapSize}px;
  height: ${(tumbBoxHeight * 2) + (paddingSize * 2) + gapSize}px;
  background: ${white};
  border-radius: 18px;
  padding: ${paddingSize}px;
  display: flex;
  flex-wrap: wrap;
  gap: ${gapSize}px;
  overflow: none;
`;

export enum productionTypes { "IEPS Data", "FLUXSUS"}
type coloProps = {productionBy: productionTypes;}

export const ThumbBox = styled.div<coloProps>`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background: ${({ productionBy }) => productionBy === productionTypes['IEPS Data'] ? "#e26d6f" : "#bb7172"};
  width: ${tumbBoxWidth}px;
  height: ${tumbBoxHeight}px;
  padding: 16px;
  margin: 0px 0px;
  border-radius: 8px;
  cursor: pointer;
`;

export const ThumbDate = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  color: ${white};
`;

export const ThumbTitle = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 2.0rem;
  line-height: 2.0rem;
  margin-bottom: 1rem;
  color: ${white};
`;

export const ThumbParagraph = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  color: ${white};
  display: block; 
  display: -webkit-box;
  width: 260px;
  white-space: balance; 
  overflow: hidden;
  text-overflow: ellipsis; 
`;

export const ThumbLink = styled.a`
  position: absolute;
  text-decoration: none;
  display: block;
  width: ${tumbBoxWidth}px;
  height: ${tumbBoxHeight}px;
  top: 0px;
  left: 0px;
  z-index: 1;
`;

export const ThumbTag = styled.div`
  border: 0.5px solid ${geyser};
  position: absolute;
  font-size: 1rem;
  color: ${white};
  top: ${paddingSize}px;
  right: ${paddingSize}px;
  text-decoration: none;
  display: block;
  padding: 0.4rem;
`;

export const SlideControl = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 8px;
  gap: 4px;
`

const buttonStyle = css`
  background: ${white};
  border: 0.5px solid ${geyser};
  color: ${paleSky};
  cursor: pointer;
  font-size: 1.4rem;
  outline: transparent;
  padding: 4px 8px;
`;

export const PaginationButton = styled.button`
  ${buttonStyle}
  border-radius: 4px 0px 0px 4px;
`;